<template>
  <dashboard>
    <div v-if="isOnFreePlan">
      <el-alert
        :closable="false" class="mb-3"
        title="You are currently on the free plan"
      >
        <span class="mb-2 d-block">You can post 1 job which the freelancer can apply to but you cannot communicate with the applicants until you upgrade to a paid plan. </span>
        <span class="d-block">It will take up to 24-72 hours before your job post will be live as it needs to be verified and your job post may not contain any links or identifiable information.</span>
        <center>
          <router-link to="/employer/billing">
            <el-button size="mini" class="block mt-2 mb-1" type="warning">Upgrade now</el-button>
          </router-link>
        </center>
      </el-alert>
    </div>
    
    <el-row id="employer" :gutter="15">
      <el-col :xs="24">
        <div class="jobfeed feed">
          <el-card class="job">
            <el-row :gutter="15" class="mb-3">
              <el-col :xs="16" :sm="16">
                <h4 class="mt-3 mb-0 ml-3 bold orange-text">Your Job Posts</h4>
              </el-col>
              <el-col :xs="8" :sm="8">
                <div class="text-right add-job">
                  <router-link to="/employer/jobs/create">
                    <el-button type="warning" size="small" class="mt-3 mr-3 title-button-mobile"><i class="el-icon-plus"></i><span class="hidden-xs">Add job post</span></el-button>
                  </router-link>
                </div>
              </el-col>
            </el-row>
            <hr class="">
            <employer-job-tabs class="pt-2"/>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </dashboard>
</template>

<script>
import Dashboard from '~/components/Dashboard/Dashboard'
import EmployerJobTabs from '~/components/Jobs/Employer/Tabs'
import checksIfOnFreePlan from '~/mixins/checksIfOnFreePlan'

export default {
  components: { Dashboard, EmployerJobTabs },

  mixins: [checksIfOnFreePlan],
}
</script>
<style lang="scss" scoped>
  .el-alert {
    background-color: #fdf6ec;
    border: 1px solid #e87622;
  }
  
  .el-alert--info.is-light, .d-block {
   color: #333; 
  }
</style>
<style lang="scss">
  #employer {
    .job-row:first-child{
      border-top: none;
    }
    .job-row:last-child{
      border-bottom: 1px solid #f6f6f6;
      margin-bottom: 20px;
    }
  }
</style>
