<template>
  <el-tabs v-model="jobPanel" class="employer-job-tab">
    <el-tab-pane label="Active Jobs" name="active">
      <list
        :jobs="activeJobs"
        :page.sync="activePage"
        :total-results="totalActive"
        :per-page="perPage"
      />
    </el-tab-pane>

    <el-tab-pane label="Deactivated Jobs" name="inactive">
      <list
        :jobs="deactivatedJobs"
        :page.sync="deactivatedPage"
        :total-results="totalDeactivated"
        :per-page="perPage"
        empty-text="You have no deactivated jobs."
        hide-add-jobs-button
      />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import List from '~/components/Jobs/Employer/List'
import Job from '~/models/Jobs/EmployerJob'

export default {
  components: { List },

  props: {
    perPage: {
      type: Number,
      default: 10,
    },
  },

  data () {
    return {
      jobPanel: 'active',
      activeJobs: [],
      deactivatedJobs: [],
      activePage: 1,
      deactivatedPage: 1,
      totalActive: 0,
      totalDeactivated: 0,
    }
  },

  watch: {
    activePage () {
      this.fetchActive()
    },

    deactivatedPage () {
      this.fetchDeactivated()
    },
  },

  created () {
    this.fetchActive()
    this.fetchDeactivated()
  },

  methods: {
    fetchActive () {
      Job.all(this.activePage, this.perPage, { scope: 'public' })
        .then(response => {
          this.activeJobs = response.data.data
          this.totalActive = response.data.total
        })
    },

    fetchDeactivated () {
      Job.all(this.deactivatedPage, this.perPage, { scope: 'deactivated' })
        .then(response => {
          this.deactivatedJobs = response.data.data
          this.totalDeactivated = response.data.total
        })
    },
  },
}
</script>
<style>
  .employer-job-tab>.el-tabs__header {
    padding: 0 10px;
  }
</style>
