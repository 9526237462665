<template>
  <div>
    <div v-if="jobs.length > 0" class="body">
      <div v-for="item in jobs" :key="item.job_id" class="job-row">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="17" :md="14" :lg="18">
            <router-link :to="`/employer/jobs/${item.job_id + tempCountId}`">
              <div class="">
                <h6 class="bold mb-1">{{ item.title }}</h6>
                <p v-if="item.applications_count > 0" class="small mt-2">{{ item.applications_count }} Applicant{{ item.applications_count > 1 ? 's' : ''}} for Review</p>
              </div>
            </router-link>
          </el-col>
          <el-col :xs="24" :sm="7" :md="10" :lg="6" class="text-left text-md-right align-self-center">
            <router-link :to="`/employer/jobs/${item.job_id + tempCountId}/applications`">
              <el-button type="success" class="mb-3 mt-2" size="small">View Applications</el-button>
            </router-link>
          </el-col>
        </el-row>
      </div>
    </div>

    <div v-else class="pl-3 pr-3 text-center mb-3">
      <p class="small" v-text="emptyText" />
      <router-link v-if="! hideAddJobsButton" to="/employer/jobs/create"><el-button type="success">Add job post</el-button></router-link>
    </div>

    <div v-if="totalResults > perPage" class="job-pagination mx-auto mb-4">
      <el-pagination
        background
        :current-page.sync="localPage"
        layout="prev, pager, next"
        :total="totalResults"
        :page-size="perPage"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    jobs: {
      type: Array,
      required: true,
    },
    page: {
      type: Number,
      default: 1,
    },
    totalResults: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    hideAddJobsButton: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: 'You currently have no job posted.',
    },
  },
  data() {
    return {
      tempCountId:10000
    }
  },
  computed: {
    localPage: {
      get () {
        return this.page
      },

      set (value) {
        this.$emit('update:page', value)
      },
    },
  },
}
</script>
