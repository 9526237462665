import Job from '~/models/Jobs/Job'

export default class EmployerJob extends Job {
  constructor (id) {
    super(id)

    this.deactivating = false
  }

  static get resource () {
    return 'employer/jobs'
  }

  static get resourceWrapped () {
    return true
  }

  confirmDeactivate () {
    super.confirmDelete({
      modifier: 'deactivate',
      successMessage: 'Successfully deactivated!',
      redirect: false,
      loadingStatusProperty: 'deactivating',
    }).then(response => {
      this.data = response.data.data
    })
  }

  confirmReactivate () {
    super.confirmDelete({
      modifier: 'reactivate',
      successMessage: 'Successfully re-activated!',
      redirect: false,
      loadingStatusProperty: 'deactivating',
    }).then(response => {
      this.data = response.data.data
    })
  }
}
